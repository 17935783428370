import qs from "qs";
import axios from "axios";

import { fromLS } from "./util";

const { REACT_APP_SERVER_HOST = "" } = process.env;

const tokenFromLS = fromLS("auth_token");
const axiosAPIInstance = axios.create({ baseURL: REACT_APP_SERVER_HOST });

const AuthAndConfig = {
  async login({ username, password }) {
    const { data: loginReponse } = await axiosAPIInstance.post("/auth/login", {
      username,
      password,
    });
    return loginReponse;
  },
  async authCheck() {
    const { data: response } = await axiosAPIInstance.get("/auth/authcheck", {
      headers: { Authorization: tokenFromLS.get() },
    });
    return response;
  },
};

const AppConfig = {
  async getAppConfig(key) {
    return axiosAPIInstance.get(`/api/appconfig?key=${key}`).then(({ data }) => data);
  },
};

const Report = {
  async searchOnAYA({ vehicleNo }) {
    return axiosAPIInstance
      .post(`/api/bot/aya/findpolicies`, {
        riskname: vehicleNo,
      })
      .then(({ data }) => data);
  },

  async open({ policy }) {
    return axiosAPIInstance
      .post(`/api/claimprocess/claimcase/manualcreate`, { policy })
      .then(({ data }) => data);
  },

  async inbox(queryObject, source) {
    const queryString = qs.stringify(queryObject);
    return axiosAPIInstance
      .get(`/api/claimprocess/claimcase/inbox?${queryString}`, {
        cancelToken: source.token,
      })
      .then(({ data }) => data);
  },

  async createClaimCase(data) {
    return axiosAPIInstance
      .post(`/api/claimprocess/claimcase/createcase`, data)
      .then(({ data }) => data);
  },

  async createEClaim(data) {
    return axiosAPIInstance
      .post(`/api/claimprocess/claimcase/eclaim`, data)
      .then(({ data }) => data);
  },

  async createNonMotorEClaim(data) {
    return axiosAPIInstance
      .post(`/api/claimprocess/claimcase/nonmotoreclaim`, data)
      .then(({ data }) => data);
  },

  async closeNonMotorClaim(id, data) {
    return axiosAPIInstance
      .post(`/api/claimprocess/claimcase/${id}/close-non-motor-claim`, data)
      .then(({ data }) => data);
  },

  async getClaimCase(id) {
    return axiosAPIInstance
      .get(`/api/claimprocess/claimcase/${id}/detail`)
      .then(({ data }) => data);
  },

  async getClaimCaseByAppointmentId(id) {
    return axiosAPIInstance
      .get(`/api/claimprocess/claimcase-by-appointment/${id}/detail`)
      .then(({ data }) => data);
  },

  async getClaimCaseByRepairJobId(id) {
    return axiosAPIInstance
      .get(`/api/claimprocess/claimcase-by-repairjob/${id}/detail`)
      .then(({ data }) => data);
  },

  async acceptReport(id) {
    return axiosAPIInstance
      .post(`/api/claimprocess/claimcase/${id}/agent-accept`)
      .then(({ data }) => data);
  },

  async rejectReport(id, reason) {
    return axiosAPIInstance
      .post(
        `/api/claimprocess/claimcase/${id}/agent-reject`,
        { reason },
        {
          headers: {
            Authorization: tokenFromLS.get(),
          },
        },
      )
      .then(({ data }) => data);
  },

  async assignWorkshop(id, workshopId, vehicleNo, comment, isPartsSupplier) {
    return axiosAPIInstance
      .post(
        `/api/claimprocess/repairjob/${id}/${
          isPartsSupplier ? "assign-parts-supplier" : "workshop-assign"
        }`,
        {
          workshopId,
          vehicleNo,
          comment: comment.length > 0 ? comment : "",
        },
      )
      .then(({ data }) => data);
  },

  async syncWithCRM(id) {
    return axiosAPIInstance
      .post(`/api/claimprocess/claimcase/${id}/sync-with-crm`)
      .then(({ data }) => data);
  },
};

const Appointment = {
  async nearBySurveyorsSearch({ lat, long, radius, availability }) {
    const queryString = qs.stringify(
      {
        query: { lat, long, radius, availability },
      },
      { encode: false },
    );

    return axiosAPIInstance
      .get(`/api/claimprocess/appointment/nearbysurveyors?${queryString}`)
      .then(({ data }) => data);
  },

  async notiForm(appointmentId) {
    return axiosAPIInstance
      .get(`/api/claimprocess/appointment/${appointmentId}/noti`)
      .then(({ data }) => data);
  },

  async makeAppointment({
    reportId,
    surveyorId,
    issueNo,
    policyNo,
    remark,
    caseUrgency,
    recommendedWorkshopIds,
  }) {
    return axiosAPIInstance
      .post(`/api/claimprocess/appointment/create`, {
        reportId,
        surveyorId,
        issueNo,
        policyNo,
        remark,
        caseUrgency,
        recommendedWorkshopIds,
      })
      .then(({ data }) => data);
  },

  async forSurveyor({ appointmentId }) {
    return axiosAPIInstance
      .get(`/api/claimprocess/appointment/${appointmentId}/detail`)
      .then(({ data }) => data);
  },

  async addThirdParty({ appointmentId }) {
    return axiosAPIInstance
      .post(`/api/claimprocess/appointment/${appointmentId}/add-third-party`, {
        appointmentId,
      })
      .then(({ data }) => data);
  },

  async getThirdParty({ thirdPartyReportId }) {
    return axiosAPIInstance
      .get(`/api/claimprocess/third-party/${thirdPartyReportId}/detail`)
      .then(({ data }) => data);
  },

  async listBySurveyor(query, pagination = { sort: "-createdAt" }) {
    const queryString = qs.stringify({ query, ...pagination }, { encode: false });

    return axiosAPIInstance
      .get(`/api/claimprocess/appointment/list?${queryString}`)
      .then(({ data }) => data);
  },

  async list({ query: { claimCaseId, ...others } }) {
    const queryString = qs.stringify(
      {
        query: {
          claimCaseId,
          ...others,
        },
        sort: "-createdAt",
      },
      { encode: false },
    );

    return axiosAPIInstance
      .get(`/api/claimprocess/appointment/list?${queryString}`)
      .then(({ data }) => data);
  },

  async generateReport({ appointmentId }) {
    return axiosAPIInstance
      .get(`/api/claimprocess/appointment/${appointmentId}/generate-report`)
      .then(({ data }) => data);
  },

  async submitReport({ appointmentId, payload }) {
    return axiosAPIInstance
      .post(`/api/claimprocess/appointment/${appointmentId}/submit`, payload)
      .then(({ data }) => data);
  },

  async submitThirdPartyReport({ thirdPartyReportId, payload }) {
    return axiosAPIInstance
      .post(`/api/claimprocess/third-party/${thirdPartyReportId}/submit`, payload)
      .then(({ data }) => data);
  },

  async getKfKList() {
    return axiosAPIInstance.get(`/api/claimprocess/third-party/kfk-list`).then(({ data }) => data);
  },

  async getCustomerPolicyData(policyNo, issueNo, vehicleNo) {
    return axiosAPIInstance
      .get(`/api/bot/aya/appointment-data-from-policy`, {
        params: {
          policyNo,
          issueNo,
          vehicleNo,
        },
        timeout: 180000,
      })
      .then(({ data }) => data);
  },
};

const Repair = {
  async assignWorkshop(claimCaseId, workshopId) {
    return axiosAPIInstance
      .post(`/api/claimprocess/repairjob/agent-assgin`, {
        claimCaseId,
        workshopId,
      })
      .then(({ data }) => data);
  },

  async cancelWorkshop(claimCaseId, repairJobId) {
    return axiosAPIInstance
      .post(`/api/claimprocess/repairjob/${repairJobId}/workshop-cancel`, {
        claimCaseId,
      })
      .then(({ data }) => data);
  },

  async cancelAllWorkshop(claimCaseId) {
    return axiosAPIInstance
      .post(`/api/claimprocess/repairjob/${claimCaseId}/all-workshop-cancel`, {})
      .then(({ data }) => data);
  },

  async notiForm(repairJob) {
    return axiosAPIInstance
      .post(`/api/claimprocess/repairjob/${repairJob._id}/noti`)
      .then(({ data }) => data);
  },

  async workshopList() {
    const queryString = qs.stringify(
      {
        query: {},
        sort: "name",
      },
      { encode: false },
    );

    return axiosAPIInstance.get(`/api/org/workshop/list?${queryString}`).then(({ data }) => data);
  },

  async listByWorkshop(query, skip, limit, source) {
    const queryString = qs.stringify(
      {
        skip,
        limit,
        query,
        sort: "-createdAt",
      },
      { encode: false },
    );

    return axiosAPIInstance
      .get(`/api/claimprocess/repairjob/list?${queryString}`, {
        cancelToken: source.token,
      })
      .then(({ data }) => data);
  },

  async listPaymentsByWorkshopId(workshopId, skip = 0, limit = 20, st, ed) {
    const queryString = qs.stringify({
      skip,
      limit,
      st,
      ed,
    });

    return axiosAPIInstance
      .get(`/api/org/workshop/payment/${workshopId}?${queryString}`)
      .then(({ data }) => data);
  },

  async list({ query: { claimCaseId } }) {
    const queryString = qs.stringify(
      {
        query: {
          claimCaseId,
        },
        sort: "-createdAt",
      },
      { encode: false },
    );

    return axiosAPIInstance
      .get(`/api/claimprocess/repairjob/list?${queryString}`)
      .then(({ data }) => data);
  },

  async forCustomer({ repairJobId }) {
    return axiosAPIInstance
      .get(`/api/claimprocess/repairjob/${repairJobId}/detail`)
      .then(({ data }) => data);
  },

  async forCustomerView({ claimCaseId }) {
    return axiosAPIInstance
      .get(`/api/claimprocess/claimcase/${claimCaseId}/repair-jobs`)
      .then(({ data }) => data);
  },

  async selectPickupDate({ repairJobId, ...rest }) {
    return axiosAPIInstance.post(`/api/claimprocess/repairjob/${repairJobId}/post-repair-updates`, {
      ...rest,
    });
  },

  async setActualPickupDate({ repairJobId, ...payload }) {
    return axiosAPIInstance.post(
      `/api/claimprocess/repairjob/${repairJobId}/post-repair-updates`,
      payload,
    );
  },

  async customerSubmitReview({ repairJobId, review }) {
    return axiosAPIInstance.post(`/api/claimprocess/repairjob/${repairJobId}/customer-review`, {
      ...review,
    });
  },

  async workshopAuthorized({ repairJobId, reportId, authorizedRemark }) {
    return axiosAPIInstance
      .post(`/api/claimprocess/repairjob/${repairJobId}/authorized`, {
        reportId,
        authorizedRemark,
      })
      .then(({ data }) => data);
  },

  async partSupplierAuthorized({ repairJobId, authorizedRemark }) {
    return axiosAPIInstance
      .post(`/api/claimprocess/repairjob/${repairJobId}/authorize-parts-supplier-estimate`, {
        authorizedRemark,
      })
      .then(({ data }) => data);
  },

  async generateReport({ repairJobId }) {
    return axiosAPIInstance
      .get(`/api/claimprocess/repairjob/${repairJobId}/generate-report`)
      .then(({ data }) => data);
  },

  async detail({ repairJobId }) {
    return axiosAPIInstance
      .get(`/api/claimprocess/repairjob/${repairJobId}/detail`)
      .then(({ data }) => data);
  },

  async submitReport({ repairJobId, payload }) {
    return axiosAPIInstance
      .post(`/api/claimprocess/repairjob/${repairJobId}/submit`, payload)
      .then(({ data }) => data);
  },

  async submitAdditionalInvoice({ repairJobId, payload }) {
    return axiosAPIInstance
      .post(`/api/claimprocess/repairjob/${repairJobId}/submitAdditionalInvoice`, payload)
      .then(({ data }) => data);
  },

  async authorizeAdditionalInvoice({ repairJobId, payload }) {
    return axiosAPIInstance
      .post(`/api/claimprocess/repairjob/${repairJobId}/authorizeAdditionalInvoice`, payload)
      .then(({ data }) => data);
  },

  async submitPartSupplierReport({ repairJobId, payload }) {
    return axiosAPIInstance
      .post(`/api/claimprocess/repairjob/${repairJobId}/parts-supplier-submit`, payload)
      .then(({ data }) => data);
  },

  async issuePartsSupplierJobAssignmentNoti({ repairJobId }) {
    return axiosAPIInstance.post(
      `/api/claimprocess/repairjob/${repairJobId}/issue-job-assign-noti`,
    );
  },

  async submitActualPartArrivalDate({ repairJobId, date }) {
    return axiosAPIInstance.post(
      `/api/claimprocess/repairjob/${repairJobId}/parts-supplier-submit-actual-arrival-date`,
      { date },
    );
  },

  async addSalvagePartsRegistration({ payload }) {
    return axiosAPIInstance.post(`/api/salvage-parts`, payload).then(({ data }) => data);
  },

  async submitSalvagePartsRegistration({ payload }) {
    return axiosAPIInstance.post(`/api/salvage-parts`, payload).then(({ data }) => data);
  },

  async getSalvagePartsRegistration({ registrationId }) {
    return axiosAPIInstance.get(`/api/salvage-parts/${registrationId}`).then(({ data }) => data);
  },

  async listSalvagePartsRegistrations({ claimCaseId }) {
    return axiosAPIInstance
      .get(`/api/salvage-parts/by-claimcase/${claimCaseId}`)
      .then(({ data }) => data);
  },

  async uploadContractAttachments({ repairJobId, remark, contract_attachments }) {
    return axiosAPIInstance
      .post(
        `/api/claimprocess/repairjob/${repairJobId}/parts-supplier-assessor-upload-contract-attachments`,
        { remark, contract_attachments },
      )
      .then(({ data }) => data);
  },
};

const Organization = {
  async getSurveyors({ query = {}, limit = 10, skip = 0, sort = "createdAt" }) {
    const queryString = qs.stringify(
      {
        query,
        limit,
        sort,
        skip,
      },
      { encode: false },
    );

    return axiosAPIInstance.get(`/api/org/surveyor/list?${queryString}`).then(({ data }) => data);
  },

  async getWorkshops({ query = {}, limit = 10, skip = 0, sort = "createdAt" }) {
    const queryString = qs.stringify(
      {
        query,
        limit,
        sort,
        skip,
      },
      { encode: false },
    );
    return axiosAPIInstance.get(`/api/org/workshop/list?${queryString}`).then(({ data }) => data);
  },

  async addWorkshopMapping(mappings) {
    return axiosAPIInstance.post("/api/org/workshop/mapping", mappings).then(({ data }) => data);
  },

  async getDrawingConfig(source) {
    return axiosAPIInstance
      .get(`/api/org/drawing-config`, {
        cancelToken: source.token,
      })
      .then(({ data }) => data);
  },
  async setDrawingConfig(config) {
    return axiosAPIInstance.post(`/api/org/drawing-config`, config).then(({ data }) => data);
  },

  async updateWorkshopInfo({ workshopId, updates }) {
    return axiosAPIInstance.post(`/api/org/workshop/update/${workshopId}`, { ...updates });
  },

  async getWorkshopReviews({ workshopId, searchParams = {} }) {
    const { query, limit, sort, skip } = searchParams;
    const queryString = qs.stringify(
      {
        query,
        limit,
        sort,
        skip,
      },
      { encode: false },
    );
    return axiosAPIInstance.get(`/api/org/workshop/${workshopId}/reviews?${queryString}`);
  },
};

const ClaimCase = {
  async getCRMAccidentDescription({ dynamic365ClaimCaseId }) {
    return axiosAPIInstance
      .get(`/api/claimprocess/claimcase/accident-description`, {
        params: {
          dynamic365CaseId: dynamic365ClaimCaseId,
        },
      })
      .then(({ data }) => data);
  },

  async getCRMAssessorName({ dynamic365ClaimCaseId }) {
    return axiosAPIInstance
      .get(`/api/claimprocess/claimcase/assessor-name`, {
        params: {
          dynamic365ClaimCaseId,
        },
      })
      .then(({ data }) => data);
  },

  async getClaimsOverview({ query = {}, limit = 25, skip = 0 }, source) {
    const queryString = qs.stringify(
      {
        query,
        skip,
        limit,
      },
      {
        encode: false,
        skipNulls: true,
      },
    );

    return axiosAPIInstance
      .get(`/api/claimcases/overview?${queryString}`, {
        cancelToken: source.token,
      })
      .then(({ data }) => data);
  },

  async getClaimantPreferences({ claimCaseId }) {
    return axiosAPIInstance
      .get(`/api/claimprocess/claimcase/${claimCaseId}/claimant-preferences`)
      .then(({ data }) => data);
  },

  async updateClaimantPreferences({ claimCaseId, preferences }) {
    return axiosAPIInstance
      .post(`/api/claimprocess/claimcase/${claimCaseId}/claimant-preferences`, { ...preferences })
      .then(({ data }) => data);
  },

  async claimantPreferencesCRMhooks({ claimCaseId, isPaidDate }) {
    return axiosAPIInstance
      .post(`/api/claimprocess/claimcase/${claimCaseId}/claimant-preferences-crm-hooks`, {
        isPaidDate,
      })
      .then(({ data }) => data);
  },

  async getCustomerView({ claimCaseId }) {
    return axiosAPIInstance
      .get(`/api/claimprocess/claimcase/${claimCaseId}/customer-view`)
      .then(({ data }) => data);
  },

  async setDetermineOverallWorkshopStatusWith({ claimCaseId, repairJobId }) {
    return axiosAPIInstance
      .post(
        `/api/claimprocess/claimcase/${claimCaseId}/set-determine-overall-workshop-status-with`,
        {
          repairJobId,
        },
      )
      .then(({ data }) => data);
  },
};

const ReportFile = {
  async getReportFilesOverview() {
    return axiosAPIInstance.get("/api/claimprocess/reportfile/overview").then(({ data }) => data);
  },

  async resetFailedReport(id) {
    return axiosAPIInstance
      .post(`/api/claimprocess/reportfile/${id}/reset`)
      .then(({ data }) => data);
  },

  async enqueueReport(payload) {
    return axiosAPIInstance.post(`/api/report-file/enqueue`, payload).then(({ data }) => data);
  },
};

const Util = {
  async claimCaseSearch({ search }) {
    const queryString = qs.stringify({
      query: { search },
    });
    return axiosAPIInstance
      .get(`/api/claimprocess/claimcase/textSearch?${queryString}`)
      .then(({ data }) => data);
  },
};

const Estimate = {
  async create(estimateData) {
    return axiosAPIInstance.post("/api/estimates", estimateData).then(({ data }) => data);
  },

  async getById(id) {
    return axiosAPIInstance.get(`/api/estimates/${id}`).then(({ data }) => data);
  },

  async assignWorkshop(estimateId, workshopId) {
    return axiosAPIInstance
      .post(`/api/estimates/${estimateId}/workshops`, { workshopId })
      .then(({ data }) => data);
  },

  async removeWorkshopAssignment(assignmentId) {
    return axiosAPIInstance
      .delete(`/api/estimates/assignments/${assignmentId}`)
      .then(({ data }) => data);
  },

  async submitWorkshopEstimate(assignmentId, data) {
    return axiosAPIInstance
      .post(`/api/estimates/assignments/${assignmentId}/submit`, data)
      .then(({ data }) => data);
  },

  async getWorkshopEstimates(workshopId, status, skip = 0, limit = 10) {
    const queryString = qs.stringify({
      status,
      skip,
      limit,
    });
    return axiosAPIInstance
      .get(`/api/estimates/workshop/${workshopId}?${queryString}`)
      .then(({ data }) => data);
  },

  async resetWorkshopEstimate(assignmentId) {
    return axiosAPIInstance
      .post(`/api/estimates/assignments/${assignmentId}/reset`)
      .then(({ data }) => data);
  },

  async getWorkshopAssignment(estimateId, workshopId) {
    return axiosAPIInstance
      .get(`/api/estimates/${estimateId}/workshops/${workshopId}`)
      .then(({ data }) => data);
  },

  async getAssignmentById(assignmentId) {
    return axiosAPIInstance
      .get(`/api/estimates/assignments/${assignmentId}`)
      .then(({ data }) => data);
  },

  async getByClaimCase(claimCaseId) {
    return axiosAPIInstance
      .get(`/api/estimates/claim-case/${claimCaseId}`)
      .then(({ data }) => data);
  },

  async assignRepairJobToWorkshop(assignmentId, data) {
    return axiosAPIInstance
      .post(`/api/estimates/assignments/${assignmentId}/assign-repair-job`, data)
      .then(({ data }) => data);
  },
};

const SmallClaim = {
  async getRepairAuthorizations(input) {
    return axiosAPIInstance.get(`/api/small-claim/authorizations`, input).then(({ data }) => data);
  },
  async retryRepairAuthorization(repairJobId) {
    return axiosAPIInstance
      .post(`/api/small-claim/authorizations/${repairJobId}/retry`)
      .then(({ data }) => data);
  },
  async createStandardDeviation(data) {
    return axiosAPIInstance.post(`/api/small-claim/std-dev`, { ...data }).then(({ data }) => data);
  },
  async getAllstandardDeviation() {
    return axiosAPIInstance.get(`/api/small-claim/std-dev`).then(({ data }) => data);
  },
  async getStandardDeviation(id) {
    return axiosAPIInstance.get(`/api/small-claim/std-dev/${id}`).then(({ data }) => data);
  },
  async updateStandardDeviation(id, data) {
    return axiosAPIInstance
      .put(`/api/small-claim/std-dev/${id}`, { ...data })
      .then(({ data }) => data);
  },
};

const API = {
  ClaimCase,
  AuthAndConfig,
  AppConfig,
  Report,
  Appointment,
  Repair,
  Organization,
  ReportFile,
  Util,
  Estimate,
  SmallClaim,
};

export default API;
